
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Select } from 'ant-design-vue';
import FilterSelection from './FilterSelection.vue';

const { Option } = Select;

export default defineComponent({
  name: 'TrainingTypeFilter',
  components: {
    FilterSelection,
    Option,
  },
  props: {
    trainingTypeList: {
      type: Array,
      require: true,
      default: [''] as string[],
    },
  },
  computed: {
    ...mapGetters('trainingFilter', ['getTrainingType']),
  },
  methods: {
    getTypeName(name: string) {
      const names = ['Regional', 'Country-Specific'];
      let n = name;
      if (names.includes(n)) n = `SMART ${name}`;
      return n;
    },
    select(key: string): void {
      this.$store.dispatch('trainingFilter/updateTrainingType', key);
    },
    clear() {
      this.$store.dispatch('trainingFilter/updateTrainingType', '');
      this.$emit('clear');
    },
  },
  emits: ['clear'],
});
