
import { defineComponent, defineAsyncComponent } from 'vue';
import { Spin } from 'ant-design-vue';
import message from 'ant-design-vue/lib/message';
import TrainingFilters from '../components/Filters/TrainingFilters/index.vue';
import Header from '../components/Training/Header.vue';

const TrainingMap = defineAsyncComponent(() => import('../components/Training/Map/index.vue'));
const PieCharts = defineAsyncComponent(() => import('../components/Training/PieCharts/index.vue'));
const Graphs = defineAsyncComponent(() => import('../components/Training/Graphs/index.vue'));

export default defineComponent({
  components: {
    TrainingMap,
    Graphs,
    PieCharts,
    TrainingFilters,
    Header,
    Spin,
  },
  async created() {
    const training = this.$store.getters['training/getDefaultValues'];
    if (training?.length && !training[0]?.length) {
      await this.$store.dispatch('training/updateDefaultData');
    }
  },
  computed: {
    getLoadingState() {
      return this.$store.getters['training/getLoadingState'];
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        message.destroy();
      } else if (newProps === 'loading') {
        message.loading('Loading data and running calculations...', 0);
      }
    },
  },
});
