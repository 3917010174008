
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Button } from 'ant-design-vue';
import GeographicLocation from '@/components/Filters/GeographicLocation/index.vue';
import DateRangeFilter from '@/components/Filters/DateRangeFilter.vue';
import OrganizationFilter from '@/components/Filters/OrganizationFilter.vue';
import TrainingTypeFilter from '@/components/Filters/TrainingTypeFilter.vue';

export default defineComponent({
  name: 'TrainingFilters',
  components: {
    GeographicLocation,
    DateRangeFilter,
    OrganizationFilter,
    TrainingTypeFilter,
    Button,
  },
  data() {
    return {
      filterClicked: true,
    };
  },
  computed: {
    ...mapGetters('trainingFilter', ['getFilterAmount', 'getStatus']),
    getOrganizationList() {
      const values = this.$store.getters['training/getDefaultValues'];
      let orgs = [] as string[];
      if (values.length > 0) {
        const index = values[0].indexOf('Trainee Organizational Affliation (Specific Name)');
        if (index !== -1) {
          values.forEach((v: string[], i: number) => {
            if (i !== 0 && index <= v.length - 1 && v[index] && !orgs.includes(v[index].trim())) orgs.push(v[index].trim());
          });
        }
      }
      orgs = orgs.sort((a, b) => (a > b ? 1 : -1));
      return orgs;
    },
    getTrainingTypeList() {
      const values = this.$store.getters['training/getDefaultValues'];
      const types = [] as string[];
      if (values.length > 0) {
        const index = values[0].indexOf('Level of Event');
        values.forEach((v: string[], i: number) => {
          if (i !== 0 && index <= v.length - 1 && v[index] && !types.includes(v[index].trim())) types.push(v[index].trim());
        });
      }
      return types;
    },
    getLocationList() {
      const scope = this.$store.getters['trainingFilter/getGeographicScope'];
      const values = this.$store.getters['training/getDefaultValues'];
      const indexes = [] as number[];
      let locations = [] as string[];
      if (scope && values.length > 0) {
        if (scope === 'National') {
          indexes.push(values[0].indexOf('Country of Training'));
        } else if (scope === 'Regional') {
          indexes.push(values[0].indexOf('Region of Training'));
        } else if (scope === 'All' || !scope) {
          indexes.push(values[0].indexOf('Region of Training'));
          indexes.push(values[0].indexOf('Country of Training'));
        }
        if (!indexes.includes(-1)) {
          values.forEach((v: string[], i: number) => {
            indexes.forEach((index:number) => {
              if (i !== 0 && index <= v.length - 1 && (/\S/).test(v[index]) && !locations.includes(v[index].trim())) locations.push(v[index].trim());
            });
          });
        }
      }
      locations = locations.sort((a, b) => (a > b ? 1 : -1));
      return locations.map((key: string) => ({ label: key, value: key }));
    },
  },
  methods: {
    clearAll() {
      this.$store.dispatch('trainingFilter/updateLocation', '');
      this.$store.dispatch('trainingFilter/updateOrganization', '');
      this.$store.dispatch('trainingFilter/updateGeographicName', '');
      this.$store.dispatch('trainingFilter/updateDate', []);
      this.$store.dispatch('trainingFilter/updateTrainingType', '');
      this.$store.dispatch('training/updateData');
    },
    applyClick() {
      this.$store.dispatch('training/updateData');
    },
    onClear() {
      this.$store.dispatch('training/updateData');
    },
  },
});
